<template>
    <form-layout
        ref="dataForm"
        :valid.sync="valid"
        :mode="mode"
        :form-title="schema.titleSingular"
        :hide-title="hideTitle"
        :hide-delete="hideDelete"
        :hide-cancel="hideCancel"
        :is-edit="isEdit"
        :is-saving="isSaving"
        :is-readonly="isReadonly"
        @save="save"
        @cancel="cancel()"
        @deleteItem="deleteItem()">
        <template v-slot:content="">
            <template v-for="field in filteredSampleFields">
                <component
                    :is="field.component"
                    v-if="!isFiltered(field.camelSingular)"
                    :key="field.value"
                    v-model="item[field.camelSingular]"
                    :field="field"
                    :filter="filter"
                    :custom-filter="isSampleSet(field) ? getSampleSetFilter() : null"
                    :is-edit="isEdit"
                    :is-readonly="isReadonly"
                    :is-dense="isDense"
                    :is-saving="isSaving"
                    :schema="schema"
                    :item="item"
                    @save="save" />
            </template>
        </template>
    </form-layout>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import dateUtility from "@/services/dateUtility"
import { greaterThan } from "@/services/filtering"

export default {
    components: {
        appSearchSelectMultiple: () => import("@/components/AppSearchSelectMultiple")
    },
    mixins: [formMixin],
    computed: {
        filteredSampleFields() {
            return this.filteredFields.filter(e => e.titleSingular != 'Code');
        }
    },
    methods: {
        isSampleSet(field) {
            return field.camelSingular === "sampleSetIds";
        },
        getSampleSetFilter() {
            return greaterThan(
                "SamplesToBeMailedOn",
                dateUtility.getCurrentDateIso(),
                "datetimeoffset");
        }
    }
};
</script>
